import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import CollapsableCard from './CollapsableCard';
import { useCollapsable } from '@/hooks/collapsable';
import { Button, Menu } from '@/components';
import { ArrowsPointingInIcon, ArrowsPointingOutIcon, CheckIcon, ChevronDownIcon, DocumentArrowUpIcon } from '@heroicons/react/24/solid';
import { useExportTargetAudiencesMutation } from '@/store/poc/poc.api';
import { useParams } from 'react-router-dom';

interface CollapsableCardsProps {
  audiences: URLTargetAudience[];
  url: string;
}

const AudienceContent: FC<{ audience: URLTargetAudience }> = ({ audience }) => (
  <div className='text-left'>
    <h4 className='mb-2'>{audience.name}</h4>
    <p>{audience.description}</p>
  </div>
);

const PurchaseStakeholderContent: FC<{ purchaseStakeholder: PurchaseStakeholder }> = ({ purchaseStakeholder }) => (
  <div className='w-full text-left'>
    <div className='mb-2 flex items-center justify-between'>
      <h5>Stakeholder: {purchaseStakeholder.name}</h5>
      <div className='flex items-center gap-4 [&>p>span]:text-slate-500'>
        <p>
          <span>Veto Power</span> {purchaseStakeholder.veto_power}
        </p>
        <p>
          <span>Proximity to benefit</span> {purchaseStakeholder.proximity_to_benefit}
        </p>
        <p>
          <span>Importance day-today</span> {purchaseStakeholder.importance_day_to_day}
        </p>
        <p>
          <span>Participate in research</span> {purchaseStakeholder.likelihood_participating_in_research}
        </p>
      </div>
    </div>
    <p>{purchaseStakeholder.description}</p>
  </div>
);

const StakeholderQuestionContent: FC<{ stakeholderQuestion: StakeholderQuestion }> = ({ stakeholderQuestion }) => (
  <div className='text-left'>
    <h5 className='mb-2'>Question: {stakeholderQuestion.question}</h5>
    <div className='flex gap-4'>
      <div className='[&>p]:mb-2 [&>p]:text-slate-500'>
        <p>Tasks</p>
        <p>Next Steps</p>
        <p>Tools</p>
        <p>Techniques</p>
      </div>
      <div className='[&>p]:mb-2'>
        <p>{stakeholderQuestion.tasks.join(', ')}</p>
        <p>{stakeholderQuestion.next_steps.join(', ')}</p>
        <p>{stakeholderQuestion.tools.join(', ')}</p>
        <p>{stakeholderQuestion.techniques.join(', ')}</p>
      </div>
    </div>
  </div>
);

const WorseCaseScenarioCard: FC<{ worseCaseScenario: WorseCaseScenario }> = ({ worseCaseScenario }) => (
  <div className='mt-4 rounded-leap bg-red-100 p-4'>
    <h5 className='mb-2'>{worseCaseScenario.description}</h5>
    <div className='[&>div>p>span]:text-slate-500'>
      <div className='mb-2 flex gap-4'>
        <p className='min-w-[120px]'>
          <span>Feelings</span>
        </p>
        <p>{worseCaseScenario.feelings.join(', ')}</p>
      </div>
      <div className='mb-2 flex gap-4'>
        <p className='min-w-[120px]'>
          <span>How to ease</span>
        </p>
        <p>{worseCaseScenario.how_to_ease}</p>
      </div>
      <div className='mb-2 flex gap-4'>
        <p className='min-w-[120px]'>
          <span>How to accept</span>
        </p>
        <p>{worseCaseScenario.how_to_accept}</p>
      </div>
    </div>
  </div>
);

const ExpertQuestionCard: FC<{ expertQuestion: ExpertQuestion }> = ({ expertQuestion }) => (
  <div className='mt-4 rounded-leap bg-orange-100 p-4'>
    <p>{expertQuestion.question}</p>
  </div>
);

const CollapsableCards: FC<CollapsableCardsProps> = ({ audiences, url }) => {
  const [exportTargetAudiences] = useExportTargetAudiencesMutation();
  const { value, setValue, getValueByKey, toggleByKey } = useCollapsable();
  const [selectedTargetAudience, setSelectedTargetAudience] = useState<number[]>([]);
  const { urlId } = useParams() as { urlId: string };

  const isAllExpand = useMemo(() => {
    const targetAudience = Object.entries(value);

    if (targetAudience.length === 0) {
      return false;
    }

    return targetAudience.filter(([key, _]) => key.startsWith('audience-')).every(([_, value]) => value);
  }, [value]);

  const handleExpandAllTargetAudience = useCallback(() => {
    setValue((prevState) => {
      const newValues: Record<string, boolean> = {};

      Object.keys(prevState).forEach((key) => key.startsWith('audience-') && (newValues[key] = !isAllExpand));

      return { ...prevState, ...newValues };
    });
  }, [setValue, isAllExpand]);

  const handleCheck = (id: number) => {
    setSelectedTargetAudience((prevState) => {
      if (prevState.includes(id)) {
        const newValues = prevState.filter((targetAudienceId) => targetAudienceId !== id);

        return newValues;
      }

      return [...prevState, id];
    });
  };

  const selectAll = () => {
    const targetAudiencesId = audiences.map(({ id }) => id);

    setSelectedTargetAudience(targetAudiencesId);
  };

  const handleExport = useCallback(
    async (target_audiences: number[]) => await exportTargetAudiences({ urlIdOrHash: urlId, target_audiences, url }),
    [exportTargetAudiences, url, urlId],
  );

  const menuItems = useMemo(
    () => [{ label: 'Export docx', onClick: () => handleExport(selectedTargetAudience), icon: <DocumentArrowUpIcon className='size-6 text-slate-400' /> }],
    [selectedTargetAudience, handleExport],
  );

  const initialValues = useMemo(
    () =>
      audiences.reduce((accum, current) => {
        accum[`audience-${current.id}`] = false;

        current.purchase_stakeholders.forEach((purchaseStakeholder) => {
          accum[`purchase-stakeholder-${purchaseStakeholder.id}`] = false;

          purchaseStakeholder.stakeholder_questions.forEach((stakeholderQuestion) => {
            accum[`stakeholder-question-${stakeholderQuestion.id}`] = false;
          });
        });

        return accum;
      }, {} as Record<string, boolean>),
    [audiences],
  );

  useEffect(() => {
    setValue(initialValues);
  }, [initialValues, setValue]);

  if (Object.entries(value).length === 0) {
    return null;
  }

  return (
    <div>
      <div className='flex items-center justify-end gap-1 [&>span]:h-6 [&>span]:w-[1px] [&>span]:bg-slate-300'>
        <Button
          onClick={handleExpandAllTargetAudience}
          className='flex items-center gap-2 bg-transparent text-slate-800 hover:bg-transparent hover:text-slate-600 active:bg-transparent'
        >
          {isAllExpand ? (
            <Fragment>
              <ArrowsPointingInIcon className='size-6' /> Collapse All
            </Fragment>
          ) : (
            <Fragment>
              <ArrowsPointingOutIcon className='size-6' /> Expand All
            </Fragment>
          )}
        </Button>
        <span></span>
        <Button className='flex items-center gap-2 bg-transparent text-slate-800 hover:bg-transparent hover:text-slate-600 active:bg-transparent' onClick={selectAll}>
          <CheckIcon className='size-6' /> Select All
        </Button>
        <span></span>
        <Menu
          items={menuItems}
          direction='left'
          className='z-20 text-slate-800'
          menuDisabled={selectedTargetAudience.length === 0}
          menuIcon={
            <div className='flex items-center gap-1 px-6 py-2 font-medium'>
              Actions <ChevronDownIcon className='size-4' />
            </div>
          }
        />
      </div>
      {audiences.map((audience) => (
        <CollapsableCard
          key={`audience-${audience.id}`}
          content={<AudienceContent audience={audience} />}
          onCheck={() => handleCheck(audience.id)}
          checked={selectedTargetAudience.includes(audience.id)}
          containerClass='bg-white'
          isOpen={getValueByKey(`audience-${audience.id}`)}
          onToggle={() => toggleByKey(`audience-${audience.id}`)}
          isLoading={audience.status === 'new'}
          extraButtons={[{ icon: <DocumentArrowUpIcon className='size-6 text-slate-500' />, onClick: () => handleExport([audience.id]) }]}
        >
          {audience.purchase_stakeholders?.map((purchaseStakeholder) => (
            <CollapsableCard
              key={`purchase-stakeholder-${purchaseStakeholder.id}`}
              content={<PurchaseStakeholderContent purchaseStakeholder={purchaseStakeholder} />}
              containerClass='bg-sky-100'
              isOpen={getValueByKey(`purchase-stakeholder-${purchaseStakeholder.id}`)}
              onToggle={() => toggleByKey(`purchase-stakeholder-${purchaseStakeholder.id}`)}
            >
              {purchaseStakeholder.stakeholder_questions.map((stakeholderQuestion) => (
                <CollapsableCard
                  key={`stakeholder-question-${stakeholderQuestion.id}`}
                  content={<StakeholderQuestionContent stakeholderQuestion={stakeholderQuestion} />}
                  containerClass='bg-white'
                  isOpen={getValueByKey(`stakeholder-question-${stakeholderQuestion.id}`)}
                  onToggle={() => toggleByKey(`stakeholder-question-${stakeholderQuestion.id}`)}
                >
                  <div className='mt-6 text-left'>
                    <h5>Worse Case Scenarios</h5>
                    {stakeholderQuestion.worse_case_scenarios.map((worseCaseScenario) => (
                      <WorseCaseScenarioCard worseCaseScenario={worseCaseScenario} key={`worse-case-scenario-${worseCaseScenario.id}`} />
                    ))}
                  </div>
                  <div className='mt-6 text-left'>
                    <h5 className='mb-2'>Expert {stakeholderQuestion.expert_questions.at(0)?.expert.name} Questions</h5>
                    <p className='text-slate-500'>{stakeholderQuestion.expert_questions.at(0)?.expert.description}</p>
                    {stakeholderQuestion.expert_questions?.map((expertQuestion) => (
                      <ExpertQuestionCard expertQuestion={expertQuestion} key={`expert-question-${expertQuestion.id}`} />
                    ))}
                  </div>
                </CollapsableCard>
              ))}
            </CollapsableCard>
          ))}
        </CollapsableCard>
      ))}
    </div>
  );
};

export default CollapsableCards;
