import { Checkbox, PriorityCell } from '@/components';
import useKeywordContext from '@/hooks/keywords';
import { LinkIcon } from '@heroicons/react/24/solid';
import { FilterFnOption, Row, createColumnHelper } from '@tanstack/react-table';
import copy from 'copy-to-clipboard';
import { useMemo } from 'react';

const columnHelper = createColumnHelper<RefineCampaignKeyword>();

const useKeywordsColumns = (readonly: boolean) => {
  const { priorities, changePriority, changeAllPriorities } = useKeywordContext();
  const stringFilter = 'stringFilter' as FilterFnOption<RefineCampaignKeyword>;
  const numberFilter = 'numberFilter' as FilterFnOption<RefineCampaignKeyword>;

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: ({ table }) => {
          return (
            <div className=' ml-1 w-full'>
              <Checkbox checked={table.getIsAllRowsSelected()} onClick={() => table.toggleAllRowsSelected()} readOnly disabled={readonly} />
            </div>
          );
        },
        cell: ({ row }) => {
          return <Checkbox checked={row.getIsSelected()} onClick={() => row.toggleSelected()} readOnly disabled={readonly} />;
        },
        enableSorting: true,
        sortingFn: (rowA: Row<RefineCampaignKeyword>, rowB: Row<RefineCampaignKeyword>) => {
          const a = rowA.getIsSelected();
          const b = rowB.getIsSelected();

          return a === b ? 0 : a ? -1 : 1;
        },
        size: 50,
      }),
      columnHelper.accessor('text', {
        header: () => <p className='text-left'>Keyword</p>,
        cell: ({ getValue }) => {
          const handleCopy = () => copy(getValue());

          return (
            <div className='flex  items-center gap-2'>
              <button onClick={handleCopy}>
                <LinkIcon className='w-5 stroke-2 text-slate-500' />
              </button>
              <p className='overflow-hidden text-ellipsis whitespace-nowrap' title={getValue()}>
                {getValue()}
              </p>
            </div>
          );
        },
        filterFn: stringFilter,
      }),
      columnHelper.accessor('target_page', {
        header: () => <p className='text-left'>Target URL</p>,
        cell: ({ getValue }) => (
          <p className='overflow-hidden text-ellipsis whitespace-nowrap' title={getValue()}>
            {getValue()}
          </p>
        ),
        filterFn: stringFilter,
      }),
      columnHelper.accessor('search_volume', {
        header: () => (
          <p className='text-right'>
            Search <br /> Volume
          </p>
        ),
        cell: ({ getValue }) => <p className='w-full text-right'>{getValue()?.toLocaleString() || '-'}</p>,
        filterFn: numberFilter,
      }),
      columnHelper.accessor('campaign_rank', {
        header: () => (
          <p className='text-right'>
            Avg.
            <br />
            Rank
          </p>
        ),
        cell: ({ getValue }) => <p className='w-full text-right'>{getValue()}</p>,
        filterFn: numberFilter,
      }),
      columnHelper.accessor('difficulty', {
        header: () => (
          <p className='whitespace-nowrap text-right'>
            Difficulty
            <br />
            (SEMR)
          </p>
        ),
        cell: ({ getValue }) => <p className='w-full text-right'>{getValue()}%</p>,
        filterFn: numberFilter,
      }),
      columnHelper.accessor('rootDomainDiff', {
        header: () => (
          <p className='text-right'>
            Domain
            <br />
            Gap
          </p>
        ),
        cell: ({ getValue }) => <p className='w-full text-right'>{getValue().toLocaleString()}</p>,
        filterFn: numberFilter,
      }),
      columnHelper.display({
        id: 'priority',
        header: ({ column }) => {
          if (readonly) {
            return <p className='mx-2 flex w-full items-center justify-center'>Priority</p>;
          }

          const getValue = () => {
            if (priorities.length !== 0 && priorities.every((i) => i.value === priorities[0].value)) {
              return priorities[0].value;
            }

            return 0;
          };
          const rows = column.getFacetedRowModel().rows.map((i) => i.original);

          const handlePriorityChange = (priority: number) => {
            changeAllPriorities(rows, priority);
          };

          return (
            <div className='flex w-full flex-col items-center justify-center gap-2'>
              <PriorityCell val={getValue()} onClick={handlePriorityChange} disabled={readonly} />
            </div>
          );
        },
        cell: ({ row }) => {
          const { original } = row;
          const getValue = () => {
            const item = priorities.find((i) => i.id === original.id);

            if (item) {
              return item.value;
            }

            return original.priority;
          };

          if (!row.getIsSelected()) {
            return null;
          }

          const handlePriorityChange = (priority: number) => {
            changePriority({ id: original.id, value: priority });
          };

          return (
            <div className='flex items-center justify-center'>
              <PriorityCell val={getValue()} onClick={handlePriorityChange} disabled={readonly} />
            </div>
          );
        },
      }),
    ],
    [priorities, changePriority, changeAllPriorities, readonly],
  );

  return columns;
};

export default useKeywordsColumns;
