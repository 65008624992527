import { SCREEN_SIZES } from '@/constants/layout';
import { useFilterContext } from '@/hooks/filters';
import { useScreenSize } from '@/hooks/screenSize';
import useTutorialContext from '@/hooks/tutorial';
import { useVirtualizer } from '@/hooks/virtualizer';
import { useCampaignsNotifications } from '@/hooks/websocket';
import { getStateItemUpdater } from '@/utils/arrayStateItem';
import campaignApi, { useGetCampaignListQuery, useLazyGetCampaignQuery, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { useUpdateUserConfigMutation } from '@/store/users/users.api';
import { getCampaignAnalysisStep } from '@/utils/campaigns';
import { VirtualItem } from '@tanstack/react-virtual';
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import DashboardSkeleton from '../../DashboardSkeleton';
import CampaignsCardsFilters from '../CampaignsCardsFilters';
import { useDispatch } from 'react-redux';
import CampaignCard from './CampaignCard';
import { INITIAL_PAGE } from '@/constants/pagination';
import { useInView } from 'react-intersection-observer';
import DashboardWithoutCampaigns from '../DashboardWithoutCampaigns';

const CampaignsCards: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateTutorialState } = useTutorialContext();
  const { filters } = useFilterContext();
  const [searchParams] = useSearchParams();

  const [saveConfigMutation] = useSaveCampaignConfigMutation();

  const parentRef = useRef<HTMLDivElement>(null);

  const { width } = useScreenSize();

  const [page, setPage] = useState(Number(INITIAL_PAGE));

  const queryArgs = useMemo(
    () => ({ ...filters, page, include: ['country', 'urls'], isArchived: searchParams.get('tab') === 'archive' } as CampaignFilters),
    [filters, page, searchParams],
  );
  const { data: campaigns, isLoading, isFetching, isSuccess, isError } = useGetCampaignListQuery(queryArgs);
  const [fetchCampaign] = useLazyGetCampaignQuery();
  const [updateUserConfig] = useUpdateUserConfigMutation();
  const { notification: campaignNotification } = useCampaignsNotifications();

  const hasNextPage = useMemo(() => campaigns && Boolean(campaigns.next), [campaigns]);
  const count = useMemo(() => (campaigns ? campaigns.results.length : 0), [campaigns]);
  const cardSize = useMemo(() => (width >= SCREEN_SIZES.xxl ? 172 : 148), [width]);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (isSuccess) {
      updateTutorialState({ run: true, tourActive: true });
    }
  }, [isSuccess, updateTutorialState]);

  useEffect(() => {
    if (!campaignNotification || !campaignNotification.data) {
      return undefined;
    }

    dispatch(
      campaignApi.util.updateQueryData('getCampaignList', queryArgs, (draft) => {
        if (!draft || !draft.results) {
          return undefined;
        }

        draft.results = getStateItemUpdater(campaignNotification.data, ({ id }, campaign) => id === campaign.id, { skipAppend: true })(draft.results);
      }) as unknown as AnyAction,
    );
  }, [campaignNotification, dispatch, queryArgs]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!campaigns) {
        return false;
      }

      for (const i of campaigns.results.filter(({ last_run }) => last_run && last_run.status !== 'done')) {
        const fetchedCampaign = await fetchCampaign({ campaignId: String(i.id) }).unwrap();

        dispatch(
          campaignApi.util.updateQueryData('getCampaignList', queryArgs, (draft) => {
            if (!draft || !draft.results) {
              return undefined;
            }

            draft.results = getStateItemUpdater(fetchedCampaign, ({ id }, campaign) => id === campaign.id, { skipAppend: true })(draft.results);
          }) as unknown as AnyAction,
        );
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [campaigns, dispatch, fetchCampaign, queryArgs]);

  useEffect(() => {
    setPage(INITIAL_PAGE);
  }, [filters]);

  const [virtualizer, items, { paddingTop, paddingBottom }] = useVirtualizer({
    count: hasNextPage ? count + 1 : count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => cardSize,
  });

  useEffect(() => {
    if (hasNextPage && !isFetching && !isLoading && !isError && inView && campaigns && campaigns.next) {
      setPage(campaigns.next);
    }
  }, [hasNextPage, isFetching, items, inView, isLoading, isError, campaigns]);

  const renderCards = ({ index, key }: VirtualItem<Element>) => {
    if (!campaigns) {
      return null;
    }

    const campaign = campaigns.results.at(index);

    const handleCampaignClick = async () => {
      if (!campaign || !campaign.last_run || campaign.last_run.status !== 'done') {
        return false;
      }

      const step = getCampaignAnalysisStep(campaign);

      updateUserConfig({ last_edited_campaign: campaign.id });

      if (!campaign.config.user_progress?.last_step) {
        await saveConfigMutation({ campaignId: campaign.id.toString(), config: { user_progress: { last_step: step } } });
      }

      navigate(generatePath('campaigns/:id/:step', { id: `${campaign.id}`, step }));
    };

    if (!campaign) {
      return <Fragment key={`${key}`} />;
    }

    return (
      <Fragment key={`${campaign.id}`}>
        <div
          className='my-2 flex w-full flex-row flex-nowrap items-center justify-between rounded-leap bg-white px-5 shadow-md first-of-type:my-0'
          style={{ height: `${cardSize}px` }}
        >
          {campaign && <CampaignCard campaign={campaign} onClick={handleCampaignClick} index={index} />}
        </div>
      </Fragment>
    );
  };

  if (isLoading) {
    return <DashboardSkeleton />;
  }

  if (campaigns && campaigns.results.length === 0 && !isLoading && !filters.name__icontains && !filters.status) {
    return <DashboardWithoutCampaigns />;
  }

  return (
    <Fragment>
      <CampaignsCardsFilters pageSetter={setPage} isFetching={isFetching} />
      {items.length === 0 && !isFetching && <p className='mt-10'>No campaigns found...</p>}
      <div ref={parentRef} className='flex max-h-[92%] w-full flex-col flex-nowrap overflow-y-auto'>
        <div className='relative w-full px-2' style={{ paddingTop, paddingBottom, height: `${virtualizer.getTotalSize()}px` }}>
          {items.map(renderCards)}
          {hasNextPage && (
            <div
              ref={ref}
              style={{ height: `${cardSize}px` }}
              className='my-2 flex w-full flex-row flex-nowrap items-center justify-between rounded-leap bg-white px-5 shadow-md first-of-type:my-0'
            >
              <p className='m-auto w-full'>{hasNextPage && 'Loading more...'}</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CampaignsCards;
