import { INITIAL_ORDERING, INITIAL_PAGE, INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { getStateItemUpdater } from '@/utils/arrayStateItem';
import { base62Encode } from '@/utils/baseX';
import { round } from '@/utils/numbers';
import api from '../api';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { parseCombinations } from '@/utils/urls';
import { getQueryArgs } from '@/utils/query';

const deserializeAnalysisData = (data: AnalysisDataJSON): AnalysisData => {
  const { ranking_page_root_domains_to_page, competitor } = data;

  const lrdGap = Math.ceil(competitor.ranking_page_root_domains_to_page - ranking_page_root_domains_to_page);
  const clientLRD = ranking_page_root_domains_to_page ? data.ranking_page_external_pages_to_page / ranking_page_root_domains_to_page : 0;
  const competitorLRD = competitor.ranking_page_root_domains_to_page ? competitor.ranking_page_external_pages_to_page / competitor.ranking_page_root_domains_to_page : 0;

  const serpFeaturesByKeyword = data.serp_features_by_keyword || [];

  return {
    location: data.location,
    isEmptyData: data.is_empty_data,
    backlinksTotal: data.backlinks_total,
    domain: data.ranking_domain,
    targetPage: data.target_page,
    targetPageId: data.target_page_id,
    totalVolume: data.search_volume,
    avgVolume: data.avg_volume,
    difficulty: data.competition,
    keyword: data.keyword,
    url: data.ranking_page,
    campaignRank: data.rank,
    pageAuthority: data.ranking_page_page_authority,
    domainAuthority: data.ranking_page_domain_authority,
    linksRootDomain: clientLRD,
    rootDomain: Math.ceil(ranking_page_root_domains_to_page),
    backlinks: Math.ceil(data.ranking_page_external_pages_to_page),
    contextRelevanceScoreAvg: data.context_relevance_score_avg,
    backlinkDADistribution: {
      '1to150': data.bucket_1to150,
      '150to300': data.bucket_150to300,
      '300to500': data.bucket_300to500,
      '500to750': data.bucket_500to750,
      '750+': data.bucket_750to1000,
    },
    total: data.total,
    domainAuthorityAvg: data.average,
    volumeGapRatio: lrdGap ? data.avg_volume / lrdGap / 100 : 0,
    ageAverage: data.average_age_days,
    velocity: {
      '3months': data.velocity_3_months,
      '6months': data.velocity_6_months,
    },
    anchorTextBuckets: {
      exactMatch: round(data.anchor_text_exact_matches, 0),
      partialMatch: round(data.anchor_text_partial_matches, 0),
      other: round(data.anchor_text_others, 0),
    },
    anchorTextBucketsCount: {
      exactMatch: round((data.anchor_text_exact_matches / 100) * data.anchors_total, 0),
      partialMatch: round((data.anchor_text_partial_matches / 100) * data.anchors_total, 0),
      other: round((data.anchor_text_others / 100) * data.anchors_total, 0),
    },
    competitor: {
      domainAuthority: competitor.ranking_page_domain_authority,
      ageAverage: competitor.average_age_days,
      pageAuthority: competitor.ranking_page_page_authority,
      linksRootDomain: competitorLRD,
      rootDomain: Math.ceil(competitor.ranking_page_root_domains_to_page),
      contextRelevanceScoreAvg: competitor.context_relevance_score_avg,
      backlinkDADistribution: {
        '1to150': competitor.bucket_1to150,
        '150to300': competitor.bucket_150to300,
        '300to500': competitor.bucket_300to500,
        '500to750': competitor.bucket_500to750,
        '750+': competitor.bucket_750to1000,
      },
      total: competitor.total,
      anchorTextBuckets: {
        exactMatch: round(competitor.anchor_text_exact_matches, 0),
        partialMatch: round(competitor.anchor_text_partial_matches, 0),
        other: round(competitor.anchor_text_others, 0),
      },
      anchorTextBucketsCount: {
        exactMatch: round((competitor.anchor_text_exact_matches / 100) * competitor.anchors_total, 0),
        partialMatch: round((competitor.anchor_text_partial_matches / 100) * competitor.anchors_total, 0),
        other: round((competitor.anchor_text_others / 100) * competitor.anchors_total, 0),
      },
      domainAuthorityAvg: competitor.average,
      linksOverLrd: data.competitor.links_over_lrd,
      rank: data.competitor.rank,
      velocity: {
        '3months': data.competitor.velocity_3_months,
        '6months': data.competitor.velocity_6_months,
      },
    },
    closeToGap: 100,
    lrdGap: Math.max(0, lrdGap),
    lrdGapKeyword: Math.ceil(data.lrd_gap_keyword || 0),
    serpFeaturesByKeyword,
    // need to add those to filter them in table by diff
    pageAuthorityDiff: Math.abs(data.ranking_page_page_authority - competitor.ranking_page_page_authority),
    linksRootDomainDiff: Math.abs(competitorLRD - clientLRD),
    contextRelevanceScoreDiff: data.context_relevance_score_avg - competitor.context_relevance_score_avg,
    ageAverageDiff: data.average_age_days - competitor.average_age_days,
    velocityDiff: {
      '3months': data.velocity_3_months - data.competitor.velocity_3_months,
      '6months': data.velocity_6_months - data.competitor.velocity_6_months,
    },
  };
};

const deserializeRefineKeyword = (data: RefineKeyword): RefineCampaignKeyword => {
  const rootDomainDiff = data.competitor.ranking_page_root_domains_to_page - data.ranking_page_root_domains_to_page;
  const serpFeaturesByKeyword = data.serp_features_by_keyword || [];
  return {
    ...data,
    serpFeaturesByKeyword,
    linksRootDomainDiff: round(data.competitor.links_root_domain - data.links_root_domain, 0),
    rootDomainDiff: rootDomainDiff > 0 ? round(rootDomainDiff, 0) : 0,
  };
};

export const deserializeScenario = (data: Scenario): Scenario => ({
  ...data,
  cvr: round(data.cvr * 100, 5),
});

const deserializeOrganicResearch = (data: OrganicResearchJSON): OrganicResearch => {
  const total =
    parseInt(data['Intent Commercial Positions']) +
    parseInt(data['Intent Informational Positions']) +
    parseInt(data['Intent Navigational Positions']) +
    parseInt(data['Intent Transactional Positions']);

  return {
    traffic: parseInt(data.Traffic),
    trafficPercentage: parseInt(data['Traffic (%)']),
    url: data.Url,
    intents: {
      commercialPosition: parseInt(data['Intent Commercial Positions']),
      informationalPosition: parseInt(data['Intent Informational Positions']),
      navigationalPosition: parseInt(data['Intent Navigational Positions']),
      transactionalPosition: parseInt(data['Intent Transactional Positions']),
    },
    totalIntents: total,
  };
};

const deserializeTopKeywords = (data: TopKeywordsJSON): TopKeywords => {
  return {
    keyword: data.Keyword,
    position: parseInt(data.Position),
    previousPosition: parseInt(data['Previous Position']),
    searchVolume: parseInt(data['Search Volume']),
    cpc: parseInt(data.CPC),
    competition: parseInt(data.Competition),
    keywordDifficulty: parseInt(data['Keyword Difficulty']),
    trafficPercentage: parseInt(data['Traffic (%)']),
    traffic: parseInt(data.Traffic),
    trafficCostPercentage: parseInt(data['Traffic Cost (%)']),
    numberOfResults: parseInt(data['Number of Results']),
    trends: data.Trends,
    //serpFeaturesByKeyword: data['SERP Features by Keyword'],
    timestamp: data.Timestamp,
  };
};

const deserializeGetCampaignList = (data: Page<CampaignJSON>): DeserializedPage<CampaignJSON> => {
  const nextPage = data.next ? Number(new URL(data.next).searchParams.get('page')) : null;
  const prevPage = data.previous ? Number(new URL(data.previous).searchParams.get('page')) : null;

  return {
    ...data,
    next: nextPage,
    previous: prevPage,
  };
};

const campaignAPI = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getCampaignList: build.query<DeserializedPage<CampaignJSON>, CampaignFilters>({
      query: ({ page = INITIAL_PAGE, page_size = INITIAL_PAGE_SIZE, sort, isArchived = false, ...rest }) => {
        const ordering = sort && sort.length ? sort.at(0) : [INITIAL_ORDERING];

        return {
          url: 'campaigns/',
          method: 'GET',
          params: {
            page: page,
            page_size: page_size,
            ordering: ordering,
            sort: (sort || []).find((i) => i === 'last_edited_campaigns'),
            is_archived: isArchived,
            ...rest,
          },
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const name = queryArgs.name__icontains || '';
        const status = queryArgs.status || '';
        const sort = queryArgs.sort && queryArgs.sort.length ? queryArgs.sort.at(0) : '-created_at';
        const isArchived = queryArgs.isArchived;

        return `${name}-${endpointName}-${status}-${sort}-${isArchived}`;
      },
      transformResponse: deserializeGetCampaignList,
      merge: (currentCache, newItems, { arg: { isArchived } }) => {
        for (const result of newItems.results) {
          currentCache.results = getStateItemUpdater(result, (a, b) => a.id === b.id)(currentCache.results);
        }

        currentCache.results = currentCache.results.filter((c) => c.is_archived === isArchived);

        currentCache.next = newItems.next;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: () => ['campaignList'],
    }),
    createCampaign: build.mutation<CampaignJSON, CreateCampaignAPIValues>({
      query: (data) => ({
        method: 'POST',
        url: 'campaigns/',
        body: data,
      }),
      invalidatesTags: ['campaignList'],
    }),
    getCampaign: build.query<CampaignJSON, { campaignId: string; include?: Array<CampaignIncludeFields>; viewToken?: string }>({
      query: ({ campaignId, include, viewToken }) => ({
        method: 'GET',
        url: `campaigns/${campaignId}/`,
        params: {
          include,
          vt: viewToken,
        },
      }),
      providesTags: ['campaign'],
    }),
    patchCampaign: build.mutation<CampaignJSON, { campaignId: string; data: Partial<CampaignJSON> }>({
      query: ({ campaignId, data }) => ({
        method: 'PATCH',
        url: `campaigns/${campaignId}/`,
        body: data,
      }),
      invalidatesTags: ['campaign', 'campaignList'],
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const queryArgs = getQueryArgs(getState, 'getCampaignList');

          if (queryArgs && queryArgs.originalArgs) {
            dispatch(
              campaignAPI.util.updateQueryData('getCampaignList', queryArgs.originalArgs, (draft) => {
                draft.results = getStateItemUpdater(data, ({ id }, campaign) => id === campaign.id)(draft.results);
              }),
            );
          }
        } catch {
          dispatch(api.util.invalidateTags(['campaignList']));
        }
      },
    }),
    getCampaignAnalysis: build.query<AnalysisDataResponse, CampaignAnalysisArgs>({
      query: ({
        campaignId,
        group_by,
        sort_urls,
        filter_excluded_domains = false,
        filter_excluded_keywords = false,
        filter_client_keyword = false,
        filter_scenario_id,
        show_hidden = false,
      }) => ({
        method: 'GET',
        url: `campaigns/${campaignId}/analysis/`,
        params: {
          filter_excluded_domains,
          filter_excluded_keywords,
          filter_client_keyword,
          group_by,
          sort_urls,
          filter_scenario_id,
          show_hidden,
        },
      }),
      transformResponse: (response: APIListResponse<AnalysisDataJSON>) => {
        const results = response.results.map(deserializeAnalysisData);

        const usefullUrls = results.filter((row) => Math.round(row.competitor.rootDomain - row.rootDomain) > 0);
        const maxGapRatio = Math.max(...usefullUrls.map((item) => item.volumeGapRatio));

        return {
          ...response,
          results: results,
          maxGapRatio: maxGapRatio,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('getCampaignAnalysis');
        await queryFulfilled;
        bugsnagSpan.end();
      },
      providesTags: () => ['campaignAnalysis'],
    }),
    getKeywordAnalysis: build.query<AnalysisDataResponse, { campaignId: string; urlId: string }>({
      query: ({ campaignId, urlId }) => ({
        method: 'GET',
        url: `campaigns/${campaignId}/url/${urlId}/keywords-analysis/`,
      }),
      transformResponse: (response: APIListResponse<AnalysisDataJSON>) => {
        const results = response.results.map(deserializeAnalysisData);

        const usefullUrls = results.filter((row) => Math.round(row.competitor.rootDomain - row.rootDomain) > 0);
        const maxGapRatio = Math.max(...usefullUrls.map((item) => item.volumeGapRatio));

        return {
          ...response,
          results: results,
          maxGapRatio,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('getKeywordAnalysis');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    getCampaignKeywords: build.query<APIListResponse<Keyword>, { campaignId: string; excludeKeyword?: string }>({
      query: ({ campaignId, excludeKeyword }) => ({
        method: 'GET',
        url: `campaigns/${campaignId}/keywords/`,
        params: {
          text__not_containing: excludeKeyword,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('getCampaignKeywords');
        await queryFulfilled;
        bugsnagSpan.end();
      },
      providesTags: () => ['campaignKeywords'],
    }),
    getCampaignUrlKeywords: build.query<APIListResponse<Keyword>, { campaignId: string; urlId: string; allocated: boolean }>({
      query: ({ campaignId, urlId, allocated }) => ({
        method: 'GET',
        url: `campaigns/${campaignId}/urls/${urlId}/keywords/?allocated=${allocated}`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('getCampaignUrlKeywords');
        await queryFulfilled;
        bugsnagSpan.end();
      },
      providesTags: () => ['campaignKeywords'],
    }),
    getRefineKeywords: build.query<APIListResponse<RefineCampaignKeyword>, { campaignId: string; url_id?: string; excluded_keywords?: boolean; excluded_domains?: boolean }>({
      query: ({ campaignId, url_id, excluded_domains = false, excluded_keywords = false }) => ({
        method: 'GET',
        url: `campaigns/${campaignId}/refine-keywords/`,
        params: {
          url_id,
          excluded_keywords,
          excluded_domains,
        },
      }),
      transformResponse: (response: APIListResponse<RefineKeyword>) => ({
        results: response.results.map((kw) => deserializeRefineKeyword(kw)),
      }),
      providesTags: () => ['refineKeywords'],
    }),
    updateRefineKeywords: build.mutation<RefineResponse, RefineCampaignKeywordsArgs>({
      query: ({ campaignId, keywords }) => ({
        method: 'PATCH',
        url: `campaigns/${campaignId}/refine-keywords/`,
        body: keywords,
      }),
      async onQueryStarted({ campaignId }, { dispatch, queryFulfilled }) {
        // Pesimistic update
        try {
          const newKws = await queryFulfilled;
          dispatch(
            campaignAPI.util.updateQueryData('getRefineKeywords', { campaignId }, (draft) => {
              const newResults = newKws.data.results.map((kw) => deserializeRefineKeyword(kw));
              Object.assign(draft, { results: newResults });
            }),
          );
        } catch {
          // component will handle error
        }
      },
      invalidatesTags: ['campaignAnalysis', 'refineKeywords'],
    }),
    refineCampaignKeywords: build.mutation<RefineResponse, RefineCampaignKeywordsArgs>({
      query: ({ campaignId, keywords }) => ({
        method: 'PATCH',
        url: `campaigns/${campaignId}/keywords/`,
        body: keywords,
      }),
      invalidatesTags: ['campaignAnalysis'],
    }),
    getCampaignCompetitorsDomains: build.query<CampaignCompetitorsDomainsResults, { campaignId: string; excluded_keywords?: boolean; excluded_domains?: boolean; url_id?: string }>(
      {
        query: ({ campaignId, excluded_domains = true, excluded_keywords = true, url_id }) => ({
          method: 'GET',
          url: `campaigns/${campaignId}/domains/`,
          params: {
            url_id,
            excluded_keywords,
            excluded_domains,
          },
        }),
        providesTags: () => ['campaignCompetitorsDomains'],
        async onQueryStarted(_, { queryFulfilled }) {
          const bugsnagSpan = BugsnagPerformance.startSpan('getCampaignCompetitorsDomains');
          await queryFulfilled;
          bugsnagSpan.end();
        },
        transformResponse: (response: CampaignCompetitorsDomainsResults) => ({
          ...response,
          results: response.results.map((item, index) => ({
            ...item,
            id: index,
          })),
        }),
      },
    ),
    refineCampaignCompetitorsDomains: build.mutation<RefineResponse, { id: string; domains: Array<CampaignCompetitorDomain> }>({
      query: ({ id, domains }) => ({
        method: 'PUT',
        url: `campaigns/${id}/domains/`,
        body: domains,
      }),
      invalidatesTags: ['campaignCompetitorsDomains', 'campaignAnalysis'],
    }),
    addCampaignUrl: build.mutation<{ response: APIListResponse<Url>; status: number | null }, AddUrlFormArgs>({
      query: ({ id, combinations }) => {
        const parsedCombinations = parseCombinations(combinations);

        return {
          method: 'POST',
          url: `campaigns/${id}/urls/`,
          body: {
            combinations: parsedCombinations,
          },
        };
      },
      transformResponse: (response: APIListResponse<Url>, meta) => {
        return { response, status: meta?.response?.status || null };
      },
    }),
    deleteCampaign: build.mutation<GenericOKResponse, { campaignId: number }>({
      query: ({ campaignId }) => ({
        method: 'DELETE',
        url: `campaigns/${campaignId}/`,
      }),
      async onQueryStarted({ campaignId }, { getState, dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const queryArgs = getQueryArgs(getState, 'getCampaignList');
          if (queryArgs && queryArgs.originalArgs) {
            dispatch(
              campaignAPI.util.updateQueryData('getCampaignList', queryArgs.originalArgs, (draft) => {
                draft.results = draft.results.filter((c) => c.id !== campaignId);
              }),
            );
          }
        } catch {
          api.util.invalidateTags(['campaignList']);
        }
      },
    }),
    getDomainOrganicPages: build.query<APIListResponse<OrganicResearch>, { url: string; search?: string; country: string; offset?: number; limit?: number }>({
      query: ({ url, search, country, offset = 0, limit = 30 }) => {
        return {
          method: 'GET',
          url: '/domain-organic-pages/',
          params: {
            domain: url,
            display_limit: limit,
            display_filter: search ? `+|Ur|Co|${search}` : '',
            display_offset: offset,
            country: country,
          },
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { url, search, country } = queryArgs;
        return `${endpointName}${url}-${search}-${country}`;
      },
      merge: (currentCache, newItems) => {
        for (const result of newItems.results) {
          currentCache.results = getStateItemUpdater(result, (a, b) => a.url === b.url)(currentCache.results);
        }
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('getDomainOrganicPages');
        await queryFulfilled;
        bugsnagSpan.end();
      },
      transformResponse: (response: APIListResponse<OrganicResearchJSON>) => ({
        ...response,
        results: response.results.map(deserializeOrganicResearch),
      }),
    }),
    getTopKeywords: build.query<APIListResponse<TopKeywords>, TopKeywordsParams>({
      query: ({ url, country, limit, keyword, keyword_containing, keyword_not_containing, search_type }) => ({
        method: 'GET',
        url: '/top-keywords/',
        params: {
          url,
          country,
          limit,
          keyword,
          keyword_containing,
          keyword_not_containing,
          search_type,
        },
      }),
      transformResponse: (response: APIListResponse<TopKeywordsJSON>) => ({
        ...response,
        results: response.results.map(deserializeTopKeywords),
      }),
    }),
    getCampaignScenarios: build.query<APIListResponse<Scenario>, { campaignId: string; is_approved?: boolean; viewToken?: string }>({
      query: ({ campaignId, is_approved, viewToken }) => ({
        method: 'GET',
        url: `/campaigns/${campaignId}/scenarios/`,
        params: {
          is_approved,
          vt: viewToken,
        },
      }),
      transformResponse: (response: APIListResponse<Scenario>) => ({
        ...response,
        results: response.results.map(deserializeScenario),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('getCampaignScenarios');
        await queryFulfilled;
        bugsnagSpan.end();
      },
      providesTags: () => ['campaignScenarios'],
      forceRefetch: () => true,
    }),
    getCampaignScenarioAnchorsOverview: build.query<APIListResponse<ScenarioAnchorsOverView>, { campaignId: number }>({
      query: ({ campaignId }) => ({
        method: 'GET',
        url: `/campaigns/${campaignId}/scenarios/anchor-overview/`,
      }),
    }),
    createCampaignScenario: build.mutation<APIResponse<Scenario>, CreateScenarioArgs>({
      query: ({ campaignId, scenario }) => ({
        method: 'POST',
        url: `campaigns/${campaignId}/scenarios/`,
        body: scenario,
      }),
      transformResponse: (response: APIResponse<Scenario>) => ({
        ...response,
        data: deserializeScenario(response.data),
      }),
    }),
    deleteCampaignScenario: build.mutation<GenericOKResponse, UpdateScenarioArgs>({
      query: ({ campaignId, scenario }) => ({
        method: 'DELETE',
        url: `/campaigns/${campaignId}/scenarios/${scenario.id}/`,
        body: scenario,
      }),
    }),
    getCampaignConfig: build.query<APIResponse<CampaignConfig>, { campaignId: string }>({
      query: ({ campaignId }) => ({
        method: 'GET',
        url: `/campaigns/${campaignId}/config/`,
      }),
      providesTags: () => ['campaignConfig'],
      forceRefetch: () => true,
    }),
    saveCampaignConfig: build.mutation<APIResponse<CampaignConfig>, CampaignConfigArgs>({
      query: ({ campaignId, config }) => {
        return {
          method: 'PATCH',
          url: `/campaigns/${campaignId}/config/`,
          body: config,
        };
      },
      async onQueryStarted({ campaignId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            campaignAPI.util.updateQueryData('getCampaignConfig', { campaignId }, (oldConfig) => {
              return { ...oldConfig, ...data };
            }),
          );
        } catch {
          dispatch(api.util.invalidateTags(['campaignConfig']));
        }
      },
    }),
    getCampaignRecommendedCategories: build.query<APIResponse<Array<string>>, { campaignId: string }>({
      query: ({ campaignId }) => ({
        method: 'GET',
        url: `/campaigns/${campaignId}/analysis/recommended/categories/`,
      }),
    }),
    updateCampaignUsers: build.mutation<GenericOKResponse, { campaignId: number; users: Array<CampaignUser> }>({
      query: ({ campaignId, users }) => ({
        method: 'PUT',
        url: `/campaigns/${campaignId}/users/`,
        body: users,
      }),
      invalidatesTags: ['companyUsersList'],
    }),
    shareScenarios: build.mutation<GenericOKResponse, ShareScenariosArgs>({
      query: ({ toEmails, campaignId }) => {
        return {
          method: 'POST',
          url: `/campaigns/${campaignId}/scenarios/share/`,
          body: {
            to_emails: toEmails,
          },
        };
      },
    }),
    getShareScenariosLink: build.query<{ accessLink: string }, GetShareScenariosArgs>({
      query: ({ campaignId }) => ({
        method: 'GET',
        url: `/campaigns/${campaignId}/scenarios/share/`,
      }),
      transformResponse: (response: { access_link: string }) => {
        return { accessLink: response.access_link };
      },
    }),
    createUrlAnchorTexts: build.mutation<APIResponse<AnchorText>, GenerateAnchorTextArgs>({
      query: ({ campaign_id, url, anchor_text }) => ({
        method: 'POST',
        url: `/campaigns/${campaign_id}/urls/${url.id}/anchors/`,
        body: anchor_text,
      }),
    }),
    campaignFetch: build.mutation<APIResponse<CampaignJSON>, { campaign: CampaignJSON }>({
      query: ({ campaign }) => ({
        method: 'POST',
        url: `/campaigns/${campaign.id}/fetch/`,
        body: {},
      }),
      invalidatesTags: ['campaignList'],
    }),
    regenerateUrlAnchorTexts: build.mutation<APIResponse<AnchorText>, RegenerateUrlAnchorTextsArgs>({
      query: ({ campaign_id, urls }) => ({
        method: 'POST',
        url: `/campaigns/${campaign_id}/anchors/regenerate/`,
        body: { urls },
      }),
    }),
    getUrlAnchorTexts: build.query<APIListResponse<UrlAnchorText>, GetAnchorTextArgs>({
      query: ({ campaign_id, url }) => ({
        method: 'GET',
        url: `/campaigns/${campaign_id}/urls/${url.id}/anchors/`,
      }),
      transformResponse: (response: APIListResponse<UrlAnchorText>, _meta, { url }) => ({
        results: response.results.map((item) => ({
          ...item,
          url: url,
        })),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('getUrlAnchorTexts');
        await queryFulfilled;
        bugsnagSpan.end();
      },
      providesTags: () => ['urlAnchorText'],
    }),
    updateUrlAnchorTexts: build.mutation<APIResponse<UrlAnchorText>, UpdateAnchorTextArgs>({
      query: ({ campaign_id, url, anchor_text }) => ({
        method: 'PATCH',
        url: `/campaigns/${campaign_id}/urls/${url.id}/anchors/${anchor_text.id}/`,
        body: {
          status: anchor_text.status,
          value: anchor_text.value,
          source: anchor_text.source,
        },
      }),
      transformResponse: (response: APIResponse<UrlAnchorText>, _meta, { url }) => ({
        data: {
          ...response.data,
          url: url,
        },
      }),
      invalidatesTags: ['urlAnchorText'],
    }),
    regenerateAnchorText: build.mutation<APIResponse<UrlAnchorText>, RegenerateAnchorTextArgs>({
      query: ({ campaign_id, url, anchor_text_id, generated_anchor_text }) => {
        const base62GeneratedAnchorText = generated_anchor_text ? base62Encode(generated_anchor_text) : undefined;
        return {
          method: 'GET',
          url: `/campaigns/${campaign_id}/urls/${url.id}/anchors/${anchor_text_id}/regenerate/${base62GeneratedAnchorText && '?anchor_to_replace=' + base62GeneratedAnchorText}`,
        };
      },
      transformResponse: (response: APIResponse<UrlAnchorText>, _meta, { url }) => ({
        data: {
          ...response.data,
          url: url,
        },
      }),
      invalidatesTags: ['urlAnchorText'],
    }),
    deleteCampaignAnchorTexts: build.mutation<GenericOKResponse, { campaignId: string }>({
      query: ({ campaignId }) => ({
        method: 'DELETE',
        url: `/campaigns/${campaignId}/anchors/`,
      }),
      invalidatesTags: ['urlAnchorText', 'campaignConfig'],
    }),
    downloadScenarioExportCsv: build.mutation<void, { campaignId: string }>({
      query: ({ campaignId }) => ({
        url: `/campaigns/${campaignId}/scenarios/export/`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.ok) {
            window.location.assign(window.URL.createObjectURL(await response.blob()));
            return { data: {} };
          }
          return await response.json();
        },
        cache: 'no-cache',
      }),
    }),
    getCountries: build.query<APIListResponse<Country>, void>({
      query: () => ({
        url: 'countries/',
        method: 'GET',
      }),
    }),
    duplicateCampaign: build.mutation<CampaignJSON, { campaignId: string }>({
      query: ({ campaignId }) => ({
        url: `campaigns/${campaignId}/duplicate/`,
        method: 'POST',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const queryData = getQueryArgs(getState, 'getCampaignList');

          if (queryData && queryData.originalArgs) {
            dispatch(
              campaignAPI.util.updateQueryData('getCampaignList', { ...queryData.originalArgs, page: 0 }, (draft) => {
                draft.results = [data, ...draft.results];
              }),
            );
          }
        } catch (e) {
          console.log(e);
          dispatch(api.util.invalidateTags(['campaignList']));
        }
      },
    }),
    createLirCampaign: build.mutation<APIResponse<Record<'url_redirect', string>>, { campaignId: string; urls: Array<LIRUrl>; keywords: Array<Keyword> }>({
      query: ({ campaignId, urls, keywords }) => {
        const newUrls = urls.map((url) => {
          if (url.type === 'campaign') {
            return { ...url, type: 'client' };
          }
          if (url.type === 'control') {
            return { ...url, type: 'competitor' };
          }
        });

        return {
          url: `campaigns/${campaignId}/lir-connect/`,
          method: 'PUT',
          body: { urls: newUrls, keywords },
        };
      },
    }),
    getLocations: build.query<Page<FetchLocation>, { name?: string; campaignId?: string; country?: string }>({
      query: ({ name, campaignId, country }) => ({
        params: { name, country },
        url: campaignId ? `/campaigns/${campaignId}/locations/` : `/locations/`,
        method: 'GET',
      }),
    }),
  }),
});

const {
  endpoints,
  useGetCampaignListQuery,
  useLazyGetCampaignListQuery,
  useGetCountriesQuery,
  useCreateCampaignMutation,
  useCampaignFetchMutation,
  useGetCampaignQuery,
  useLazyGetCampaignQuery,
  usePatchCampaignMutation,
  useDeleteCampaignMutation,
  useLazyGetCampaignAnalysisQuery,
  useAddCampaignUrlMutation,
  useGetDomainOrganicPagesQuery,
  useLazyGetDomainOrganicPagesQuery,
  useGetCampaignScenariosQuery,
  useLazyGetCampaignScenariosQuery,
  useGetCampaignConfigQuery,
  useLazyGetCampaignConfigQuery,
  useGetCampaignRecommendedCategoriesQuery,
  useSaveCampaignConfigMutation,
  useGetCampaignKeywordsQuery,
  useGetCampaignUrlKeywordsQuery,
  useLazyGetCampaignUrlKeywordsQuery,
  useGetCampaignCompetitorsDomainsQuery,
  useGetRefineKeywordsQuery,
  useLazyGetRefineKeywordsQuery,
  useUpdateRefineKeywordsMutation,
  useRefineCampaignKeywordsMutation,
  useRefineCampaignCompetitorsDomainsMutation,
  useShareScenariosMutation,
  useUpdateCampaignUsersMutation,
  useDeleteCampaignScenarioMutation,
  useLazyGetShareScenariosLinkQuery,
  useCreateCampaignScenarioMutation,
  useCreateUrlAnchorTextsMutation,
  useRegenerateUrlAnchorTextsMutation,
  useLazyGetUrlAnchorTextsQuery,
  useGetUrlAnchorTextsQuery,
  useUpdateUrlAnchorTextsMutation,
  useRegenerateAnchorTextMutation,
  useDeleteCampaignAnchorTextsMutation,
  useDownloadScenarioExportCsvMutation,
  useGetCampaignScenarioAnchorsOverviewQuery,
  useLazyGetTopKeywordsQuery,
  useGetTopKeywordsQuery,
  useDuplicateCampaignMutation,
  useCreateLirCampaignMutation,
  useGetKeywordAnalysisQuery,
  useLazyGetKeywordAnalysisQuery,
  useGetLocationsQuery,
} = campaignAPI;

export {
  endpoints,
  useAddCampaignUrlMutation,
  useCampaignFetchMutation,
  useCreateCampaignMutation,
  useCreateCampaignScenarioMutation,
  useCreateUrlAnchorTextsMutation,
  useDeleteCampaignAnchorTextsMutation,
  useDeleteCampaignScenarioMutation,
  useDownloadScenarioExportCsvMutation,
  useGetCampaignCompetitorsDomainsQuery,
  useGetCampaignConfigQuery,
  useGetCampaignRecommendedCategoriesQuery,
  useGetCampaignKeywordsQuery,
  useGetCampaignListQuery,
  useGetCampaignQuery,
  useGetCampaignScenarioAnchorsOverviewQuery,
  useGetCampaignScenariosQuery,
  useGetCampaignUrlKeywordsQuery,
  useGetCountriesQuery,
  useGetRefineKeywordsQuery,
  useGetUrlAnchorTextsQuery,
  useGetDomainOrganicPagesQuery,
  useLazyGetCampaignAnalysisQuery,
  useLazyGetCampaignConfigQuery,
  useLazyGetCampaignListQuery,
  useLazyGetCampaignQuery,
  useLazyGetCampaignScenariosQuery,
  useLazyGetCampaignUrlKeywordsQuery,
  useLazyGetDomainOrganicPagesQuery,
  useLazyGetShareScenariosLinkQuery,
  useLazyGetUrlAnchorTextsQuery,
  usePatchCampaignMutation,
  useRefineCampaignCompetitorsDomainsMutation,
  useLazyGetRefineKeywordsQuery,
  useRefineCampaignKeywordsMutation,
  useRegenerateAnchorTextMutation,
  useRegenerateUrlAnchorTextsMutation,
  useSaveCampaignConfigMutation,
  useShareScenariosMutation,
  useUpdateCampaignUsersMutation,
  useUpdateRefineKeywordsMutation,
  useUpdateUrlAnchorTextsMutation,
  useGetTopKeywordsQuery,
  useLazyGetTopKeywordsQuery,
  useDeleteCampaignMutation,
  useDuplicateCampaignMutation,
  useCreateLirCampaignMutation,
  useGetKeywordAnalysisQuery,
  useLazyGetKeywordAnalysisQuery,
  useGetLocationsQuery,
};

export default campaignAPI;
