import { ErrorMessage, NumberInputField, SelectField, TextInputField, CombinationsField } from '@/components';
import { KEYWORDS_COUNT, URLS_COUNT, URL_SELECTION_OPTIONS } from '@/constants/defaultValues';
import { getCountryOptions } from '@/utils/countries';
import { formatUrlList, parseUrls } from '@/utils/urls';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useField } from 'formik';
import DomainInputField from '../DomainInputField';
import { useSelector } from 'react-redux';
import { selectCountryList } from '@/store/campaign/campaign.selector';
import { selectAuthUser } from '@/store/auth/auth.selector';

interface NewCampaignFormProps {
  values: NewCampaignFormValues;
  onValidateCombinations: (areValid: boolean) => void;
}

const NewCampaignForm: FC<NewCampaignFormProps> = ({ values, onValidateCombinations }) => {
  const [, { error: urlsListError, touched: urlsListTouched }] = useField<string>('urls_list');
  const { url_selection, urls_list, combinations, country } = values;
  const countryList = useSelector(selectCountryList);
  const user = useSelector(selectAuthUser);

  const [maxUrlError, setMaxUrlError] = useState(false);

  const countriesOptions = useMemo(() => getCountryOptions(countryList), [countryList]);
  const maxUrls = useMemo(() => user?.company?.urls_max, [user]);

  const initialCombinations = useMemo(() => formatUrlList(urls_list || ''), [urls_list]);

  const [combinationsWithSwitch, setCombinationsWithSwitch] = useState<CombinationWithSwitchMap>(
    combinations.reduce((acc, combination, index) => {
      return {
        ...acc,
        [index]: {
          ...combination,
          toggled: false,
        },
      };
    }, {}) || {},
  );

  const handleCombinationWithSwitch = useCallback((callback: (prev: CombinationWithSwitchMap) => CombinationWithSwitchMap) => {
    setCombinationsWithSwitch(callback);
  }, []);

  useEffect(() => {
    const hasCombinationsWithoutKws = Object.values(combinationsWithSwitch).every((combination) => combination.toggled || combination.keywords.length > 0);

    onValidateCombinations(hasCombinationsWithoutKws);
  }, [combinationsWithSwitch, onValidateCombinations]);

  useEffect(() => {
    const isUrl = url_selection === 'urls_manually' || url_selection === 'from_domain';
    const urlsAmount = isUrl ? parseUrls(urls_list || '').length : combinations.length;

    if (maxUrls && urlsAmount > maxUrls) {
      setMaxUrlError(true);
      return;
    }

    setMaxUrlError(false);
  }, [combinations.length, maxUrls, url_selection, urls_list]);

  const shouldRenderCombinations = url_selection === 'urls_kws_manually' || (urls_list && urls_list.length > 0);
  const showUrsListError = !!urlsListError && urlsListTouched;

  return (
    <div className='flex w-full flex-nowrap  gap-6'>
      <div className='relative h-fit flex-1 rounded-lg border bg-white p-6 shadow-md'>
        <h3 className='text-left'>Campaign Detail</h3>
        <div className='mt-2 flex w-full flex-col flex-wrap gap-4 text-left'>
          <div className='flex w-full flex-col gap-4'>
            <div className='flex w-full flex-row flex-wrap gap-4 lg:flex-nowrap'>
              <TextInputField name='name' placeholder='Enter campaign name' label='Name' />
              <TextInputField name='client_domain' placeholder='Enter client domain' label='Subject Domain' />
            </div>

            <div className='flex w-full flex-wrap gap-4 lg:flex-nowrap'>
              <SelectField name='country' options={countriesOptions} label='Country' withMarker />
              <NumberInputField name='budget' label='Budget/month' optional suffix='$ / month' />
            </div>
            <div className='flex w-full flex-wrap gap-4 lg:flex-nowrap'>
              <SelectField options={URLS_COUNT} name='url_count' label='Competitors / Keywords' />
              <SelectField options={KEYWORDS_COUNT} name='keyword_count' label='Keywords' />
            </div>
            <SelectField name='url_selection' options={URL_SELECTION_OPTIONS} label='Url Selection' withMarker />
            {url_selection === 'from_domain' && (
              <div className='rounded-lg bg-slate-100 p-4'>
                <DomainInputField name='urls_list' country={country} />
              </div>
            )}
            {/* <CheckboxField name='backlinks_profile' label='Pull Backlinks Profile' labelPosition='right' className='justify-end' /> */}
          </div>

          {maxUrlError && <ErrorMessage>You have exceeded the maximum number of URLs: {maxUrls}</ErrorMessage>}
          {showUrsListError && <ErrorMessage>{urlsListError}</ErrorMessage>}
        </div>
      </div>
      <div className='flex h-fit flex-1'>
        {shouldRenderCombinations && (
          <div className='relative mb-10 flex-1 rounded-lg border bg-white shadow-md'>
            <section className='flex items-center p-5'>
              <h3 className='text-left'>Combination</h3>
            </section>
            <CombinationsField
              name='combinations'
              handleCombinationWithSwitch={handleCombinationWithSwitch}
              combinationsWithSwitch={combinationsWithSwitch}
              initialCombinations={initialCombinations}
              country={country}
              isLocalEnabled
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewCampaignForm;
