import {
  CarouselIcon,
  SatelinkIcon,
  CrownIcon,
  HelpCenterIcon,
  ListAltIcon,
  PhotoLibraryIcon,
  PlayCircleIcon,
  SlowMotionVideoIcon,
  QuestionMarkIcon,
  OnDemandVideoIcon,
  AnnouncementIcon,
  XLogoIcon,
  ADTopIcon,
  ADBottomIcon,
  ADMiddleIcon,
  SquareMagnifyingGlass,
  MapsHomeWorkIcon,
  AirplaneModeActiveIcon,
  LocalDiningIcon,
  XCarouselIcon,
  NewspaperIcon,
  ShareLocationIcon,
  StarCircleIcon,
  ManageSearchIcon,
  ArticleIcon,
  ChecklistIcon,
  RecentActorsIcon,
  QuizIcon,
  PersonSearchIcon,
  MobileFriendlyIcon,
  PermMediaIcon,
  RelatedTopicsIcon,
  ApplicationIcon,
  ShortVideosIcon,
  WebStoriesIcon,
  ApplicationListIcon,
  PopularStoresIcon,
  IndentedIcon,
} from '@/components/Icons';

import {
  AcademicCapIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  ChatBubbleLeftIcon,
  DocumentTextIcon,
  FolderIcon,
  FunnelIcon,
  MapPinIcon,
  PhotoIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  SparklesIcon,
  StarIcon,
  TagIcon,
} from '@heroicons/react/20/solid';
import { SVGProps } from 'react';

interface SERPFeature {
  text: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

const SERPFeaturesMap: Record<string, SERPFeature> = {
  instant_answer: { text: 'Instant Answer', icon: AnnouncementIcon },
  knowledge_panel: { text: 'Knowledge Panel', icon: AcademicCapIcon },
  carousel: { text: 'Carousel', icon: CarouselIcon },
  local_pack: { text: 'Local Pack', icon: MapPinIcon },
  top_stories: { text: 'Top Stories', icon: DocumentTextIcon },
  image_pack: { text: 'Image Pack', icon: PhotoLibraryIcon },
  sitelinks: { text: 'Sitelinks', icon: SatelinkIcon },
  reviews: { text: 'Reviews', icon: StarIcon },
  tweet: { text: 'Tweet', icon: XLogoIcon },
  video: { text: 'Video', icon: PlayCircleIcon },
  featured_video: { text: 'Featured Video', icon: OnDemandVideoIcon },
  featured_snippet: { text: 'Featured Snippet', icon: CrownIcon },
  amp: { text: 'AMP', icon: MobileFriendlyIcon },
  image: { text: 'Image', icon: PhotoIcon },
  ads_top: { text: 'Ads Top', icon: ADTopIcon },
  ads_bottom: { text: 'Ads Bottom', icon: ADBottomIcon },
  shopping_ads: { text: 'Shopping Ads', icon: ShoppingCartIcon },
  hotels_pack: { text: 'Hotels Pack', icon: MapsHomeWorkIcon },
  jobs_search: { text: 'Jobs Search', icon: BriefcaseIcon },
  featured_images: { text: 'Featured Images', icon: PermMediaIcon },
  video_carousel: { text: 'Video Carousel', icon: SlowMotionVideoIcon },
  people_also_ask: { text: 'People also ask', icon: HelpCenterIcon },
  faq: { text: 'FAQ', icon: QuestionMarkIcon },
  flights: { text: 'Flights', icon: AirplaneModeActiveIcon },
  find_results_on: { text: 'Find results on', icon: SquareMagnifyingGlass },
  recipes: { text: 'Recipes', icon: LocalDiningIcon },
  related_topics: { text: 'Related Topics', icon: RelatedTopicsIcon },
  twitter_carousel: { text: 'Twitter Carousel', icon: XCarouselIcon },
  indented: { text: 'Indented', icon: IndentedIcon },
  news: { text: 'News', icon: NewspaperIcon },
  address_pack: { text: 'Address Pack', icon: ShareLocationIcon },
  application: { text: 'Application', icon: ApplicationIcon },
  events: { text: 'Events', icon: CalendarDaysIcon },
  popular_products: { text: 'Popular Products', icon: StarCircleIcon },
  related_products: { text: 'Related Products', icon: ShoppingBagIcon },
  related_searches: { text: 'Related Searches', icon: ManageSearchIcon },
  see_results_about: { text: 'See results about', icon: ArticleIcon },
  short_videos: { text: 'Short videos', icon: ShortVideosIcon },
  web_stories: { text: 'Web stories', icon: WebStoriesIcon },
  application_list: { text: 'Application list', icon: ApplicationListIcon },
  buying_guide: { text: 'Buying guide', icon: ChecklistIcon },
  organic_carousel: { text: 'Organic Carousel', icon: RecentActorsIcon },
  things_to_know: { text: 'Things to know', icon: ListAltIcon },
  datasets: { text: 'Datasets', icon: FolderIcon },
  discussions_and_forums: { text: 'Discussions and Forums', icon: ChatBubbleLeftIcon },
  explore_brands: { text: 'Explore Brands', icon: TagIcon },
  questions_and_answers: { text: 'Questions and Answers', icon: QuizIcon },
  popular_stores: { text: 'Popular Stores', icon: PopularStoresIcon },
  refine: { text: 'Refine', icon: FunnelIcon },
  people_also_search: { text: 'People also Search', icon: PersonSearchIcon },
  ads_middle: { text: 'Ads Middle', icon: ADMiddleIcon },
  ai_overview: { text: 'AI Overview', icon: SparklesIcon },
};

export { SERPFeaturesMap };
