import Avatar from '@/components/Avatar';
import FeedbackTracker from '@/components/FeedbackTracker';
import NotificationsPopover from '@/components/NotificationsPopover';
import { getDateWithFormat } from '@/utils/dates';
import { ChartBarSquareIcon, Cog6ToothIcon, RectangleStackIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { ComponentProps, FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import SideMenuItem from '../SideMenuItem';
import { useGetCurrentUserQuery } from '@/store/users/users.api';
import apiPOC, { useGetUnreadURLsQuery } from '@/store/poc/poc.api';
import LCRIcon from '@/components/Icons/LCRIcon';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import DataPartnersModal from '@/components/DataPartnersModal';

interface SideMenuProps {
  isOpen: boolean;
}

type MenuItems =
  | 'dashboard'
  | 'campaigns'
  | 'dataAnalysis'
  | 'anchorText'
  | 'roi'
  | 'user-management'
  | 'settings'
  | 'linking-context-report'
  | 'linking-impact-report'
  | 'data-partners';
interface MenuOption {
  id: MenuItems;
  title: string;
  tutorialId?: string;
  Icon?: (props: ComponentProps<'svg'>) => JSX.Element;
  url: string;
}

const MENU: Array<MenuOption> = [
  {
    id: 'campaigns',
    tutorialId: 'tutorial-sidebar-1',
    title: 'Campaigns',
    Icon: RectangleStackIcon,
    url: '/',
  },
  {
    id: 'user-management',
    tutorialId: 'tutorial-sidebar-2',
    title: 'User management',
    Icon: UserCircleIcon,
    url: '/user-management',
  },
  {
    id: 'settings',
    title: 'Settings',
    Icon: Cog6ToothIcon,
    url: '/settings',
  },
  {
    id: 'linking-context-report',
    title: 'LCR',
    Icon: LCRIcon,
    url: '/linking-context-report',
  },
  {
    id: 'linking-impact-report',
    title: 'Link Impact Report',
    Icon: ChartBarSquareIcon,
    url: '/reports-import',
  },
];

const SideMenu: FC<SideMenuProps> = ({ isOpen }) => {
  const navigate = useNavigate();
  const { data: user } = useGetCurrentUserQuery();
  const { data: unreadURL } = useGetUnreadURLsQuery(undefined, { pollingInterval: 60_000 });
  const dispatch = useDispatch<AppDispatch>();

  const deployInfo = useMemo(() => {
    if (!window.appDeploy || !window.appDeploy.commit_id) {
      return false;
    }

    return window.appDeploy;
  }, []);

  const renderMenu = ({ id, title, Icon, url, tutorialId }: MenuOption) => {
    const isSuperuser = user && user.isSuperuser;
    const config = user?.config;
    const userManagement = id === 'user-management';
    const settings = id === 'settings';
    const linkingContextReport = id === 'linking-context-report';

    if (userManagement && config && !config.show_users && !isSuperuser) {
      return null;
    }

    if (settings && config && !config.show_settings && !config.show_payments && !isSuperuser) {
      return null;
    }

    if (linkingContextReport && config && !config.show_linking_context_report && !isSuperuser) {
      return null;
    }

    const handleSideMenuClick = async () => {
      dispatch(apiPOC.util.invalidateTags(['unreadURLs']));

      if (settings && (isSuperuser || user?.isCompanyOwner)) {
        return navigate('/settings/general');
      }

      if (userManagement) {
        return navigate('/user-management/users');
      }

      navigate(url);
    };

    const allowNotifications = linkingContextReport && unreadURL;

    return (
      <li key={id} className='flex flex-col'>
        <SideMenuItem
          isSidebarOpen={isOpen}
          onClick={handleSideMenuClick}
          Icon={Icon}
          url={url}
          title={title}
          tutorialId={tutorialId}
          allowNotification={allowNotifications && unreadURL.count > 0}
          notificationContent={
            allowNotifications ? <span className='block h-4 w-4 rounded-full bg-red-500 text-center text-[.5rem] leading-4 text-white'>{unreadURL.count}</span> : null
          }
        />
      </li>
    );
  };

  return (
    <menu className='mt-4 flex flex-col overflow-hidden'>
      <div>
        <ul className='flex w-full flex-col pr-2'>{MENU.map(renderMenu)}</ul>
      </div>
      <div className='absolute bottom-0 left-0 flex w-full flex-col pr-4'>
        <DataPartnersModal />
        <NotificationsPopover isSidebarOpen={isOpen} />
        <FeedbackTracker isSidebarOpen={isOpen} />
        <SideMenuItem
          isSidebarOpen={isOpen}
          onClick={() => navigate('profile')}
          url='/profile'
          title='Profile'
          Icon={user ? () => <Avatar user={user} displayName={false} /> : undefined}
        />
        {deployInfo && isOpen && (
          <div className='mb-2 text-center text-xs text-black'>
            <p>{getDateWithFormat(deployInfo.timestamp, 'dd MMM  HH:mm')}</p>
            <p>
              {deployInfo.commit_id.slice(0, 6)} ... {deployInfo.commit_id.slice(-6)}
            </p>
          </div>
        )}
      </div>
    </menu>
  );
};

export default SideMenu;
