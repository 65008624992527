import { FC, Fragment, useEffect, useState } from 'react';
import { Page } from '@/layouts';
import { useGetURLDetailQuery, useMarkURLAsReadMutation } from '@/store/poc/poc.api';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@/components';
import { CollapsableCards, TargetAudienceModal } from './components';
import { URLAudienceSkeleton, URLDetailSkeleton, URLDetailTopBarSkeleton } from './URLDetailPageSkeleton';
import { useToggle } from '@/hooks/toggle';
import AddTargetAudienceModal from './components/AddTargetAudienceModal';
import URLDetailCards from './components/URLDetailCards';
import { isLCRInProgress } from '@/utils/lcr';

const URLDetailPage: FC = () => {
  const navigate = useNavigate();
  const { urlId } = useParams() as { urlId: string };
  const [pollingInterval, setPollingInterval] = useState(0);
  const { data: urlDetail } = useGetURLDetailQuery({ urlIdOrHash: urlId, include: ['target_audience'] }, { pollingInterval });
  const [markUrlAsRead] = useMarkURLAsReadMutation();
  const { value: isAudienceQuestionsModalOpen, open: openAudienceQuestionsModal, close: closeAudienceQuestionsModal } = useToggle();
  const { value: isAddTargetAudienceModalOpen, open: openAddTargetAudienceModal, close: closeAddTargetAudienceModal } = useToggle();

  useEffect(() => {
    if (urlDetail && !urlDetail.read_at && urlDetail.status === 'completed') {
      markUrlAsRead(String(urlDetail.id));
    }
  }, [urlDetail, markUrlAsRead]);

  useEffect(() => {
    if (urlDetail && isLCRInProgress(urlDetail.status)) {
      return setPollingInterval(5000);
    }

    setPollingInterval(0);
  }, [urlDetail]);

  return (
    <Page>
      {urlDetail ? <Page.TopBar title={urlDetail.url} className='flex items-center' goBack={() => navigate(-1)} /> : <URLDetailTopBarSkeleton />}
      <Page.Body>
        {urlDetail ? <URLDetailCards urlDetail={urlDetail} /> : <URLDetailSkeleton />}
        <div className='my-10 flex items-center justify-between text-left'>
          <h4>Target Audience</h4>
          <div className='flex gap-2'>
            <Button variant='secondary' className='bg-white hover:bg-gray-200 active:bg-gray-200' onClick={() => openAudienceQuestionsModal()}>
              All Questions
            </Button>
            <Button onClick={() => openAddTargetAudienceModal()}>Add Target Audience</Button>
          </div>
        </div>
        {urlDetail ? (
          <Fragment>
            <CollapsableCards audiences={urlDetail.target_audience || []} url={urlDetail.url} />
            <TargetAudienceModal isOpen={isAudienceQuestionsModalOpen} onClose={closeAudienceQuestionsModal} audiences={urlDetail.target_audience || []} />
          </Fragment>
        ) : (
          <URLAudienceSkeleton />
        )}
        <AddTargetAudienceModal isOpen={isAddTargetAudienceModalOpen} onClose={closeAddTargetAudienceModal} />
      </Page.Body>
    </Page>
  );
};

export default URLDetailPage;
