const CrownIcon = () => {
  return (
    <svg width='24' height='20' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.9645 5.38389L17.6418 14.5699C17.5742 14.8359 17.4227 15.0715 17.2111 15.2397C16.9994 15.408 16.7395 15.4995 16.4719 15.5H3.53221C3.26459 15.4995 3.00466 15.408 2.79302 15.2397C2.58138 15.0715 2.42994 14.8359 2.36235 14.5699L0.0395815 5.38389C-0.0169007 5.16278 -0.0128641 4.92994 0.0512462 4.71103C0.115357 4.49213 0.237052 4.29566 0.40292 4.14327C0.568787 3.99089 0.772388 3.8885 0.991283 3.84741C1.21018 3.80631 1.43587 3.8281 1.64348 3.91036L5.49215 5.43265C5.53108 5.4482 5.57383 5.4505 5.61412 5.43921C5.65441 5.42793 5.6901 5.40365 5.71595 5.36995L9.04921 0.980728C9.16172 0.831125 9.3061 0.710003 9.47128 0.626668C9.63646 0.543332 9.81803 0.5 10.0021 0.5C10.1861 0.5 10.3677 0.543332 10.5328 0.626668C10.698 0.710003 10.8424 0.831125 10.9549 0.980728L14.2882 5.36995C14.314 5.40365 14.3497 5.42793 14.39 5.43921C14.4303 5.4505 14.473 5.4482 14.512 5.43265L18.3606 3.91036C18.5681 3.83171 18.7926 3.8126 19.0099 3.85509C19.2272 3.89759 19.4291 4.00007 19.594 4.15155C19.7589 4.30302 19.8805 4.49777 19.9458 4.71488C20.011 4.93199 20.0175 5.16327 19.9645 5.38389Z'
        fill='#94A3B8'
      />
    </svg>
  );
};

export default CrownIcon;
