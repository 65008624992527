import dataForSeoLogo from '@/assets/images/data_partners/data_for_seo_logo.png';
import mozLogo from '@/assets/images/data_partners/moz_logo.png';
import semrushLogo from '@/assets/images/data_partners/semrush_logo.png';

const DATA_PARTNERS = [
  {
    name: 'Semrush',
    logo: semrushLogo,
    metrics: ['Search Volume', 'SERP Features', 'SERP Results', 'Difficulty', 'Rank data'],
  },
  {
    name: 'MOZ',
    logo: mozLogo,
    metrics: ['DA', 'PA', 'Referring Domain count'],
  },
  {
    name: 'DataForSEO',
    logo: dataForSeoLogo,
    metrics: ['Individual Backlinks'],
  },
] as const;

export default DATA_PARTNERS;
