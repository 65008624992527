import { stringify } from 'query-string';
import { BASE_POC_API_URL } from '@/constants/application';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { getFilteredQueryArgs, getPOCQueryArgs, getQueryArgs } from '@/utils/query';
import { deserializeUser } from '../users/users.api';
import api from '../api';

const lcrAPI = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getURLList: build.query<GetURLListResponse<LCRURL<User>>, POCPagination>({
      query: ({ page }) => ({
        method: 'GET',
        url: 'lcr/urls/',
        params: {
          page,
        },
      }),
      transformResponse: (data: GetURLListResponse<LCRURL<UserJSON>>) => {
        const results = data.results.map((url) => {
          if (url.user) {
            return { ...url, user: deserializeUser(url.user) };
          }

          return { ...url, user: null };
        });

        return { ...data, results };
      },
    }),
    createURLAudience: build.mutation<LCRURL<User>, CreateURLAudienceFormValues>({
      query: ({ url }) => ({
        method: 'POST',
        url: 'lcr/urls/',
        body: {
          url,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const newURL = await queryFulfilled;
          const queryArgs = getQueryArgs(getState, 'getURLList');

          if (queryArgs && queryArgs.originalArgs) {
            dispatch(
              lcrAPI.util.updateQueryData('getURLList', queryArgs.originalArgs as POCPagination, (draft) => {
                return { count: draft.count, results: [newURL.data, ...draft.results] };
              }),
            );
          }
        } catch {
          //
        }
      },
      transformResponse: (data: LCRURL<UserJSON>) => {
        if (data.user) {
          return { ...data, user: deserializeUser(data.user) };
        }

        return { ...data, user: null };
      },
    }),
    retryURLAudience: build.mutation<LCRURL<User>, { id: number }>({
      query: ({ id }) => ({
        method: 'POST',
        url: `lcr/urls/${id}/retry/`,
        body: {},
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const updatedURL = await queryFulfilled;
          const urlParams = new URLSearchParams(window.location.search);
          const currentPage = urlParams.get('page') || '1';
          const queryArgs = getFilteredQueryArgs(getState, 'getURLList', (query) => String((query?.originalArgs as { page: number }).page) === currentPage);

          if (queryArgs && queryArgs.originalArgs) {
            dispatch(
              lcrAPI.util.updateQueryData('getURLList', queryArgs.originalArgs as POCPagination, (draft) => {
                const oldURLIndex = draft.results.findIndex((url) => url.id === updatedURL.data.id);
                const user = draft.results[oldURLIndex].user;
                draft.results[oldURLIndex] = { ...updatedURL.data, user };
                return draft;
              }),
            );
          }
        } catch {
          //
        }
      },
    }),
  }),
});

const apiPOC = createApi({
  reducerPath: 'apiPoc',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_POC_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['unreadURLs', 'getURLDetail', 'getURLSummary'],
  endpoints: (build) => ({
    getURLDetail: build.query<LCRURL, { urlIdOrHash: string; include?: Array<URLExtraFields> }>({
      query: ({ urlIdOrHash, include }) => ({
        method: 'GET',
        url: `urls/${urlIdOrHash}/?${stringify({ include }, { arrayFormat: 'bracket' })}`,
      }),
      providesTags: () => ['getURLDetail'],
    }),
    getURLTargetAudience: build.query<Array<URLTargetAudience>, string>({
      query: (urlId) => ({
        method: 'GET',
        url: `urls/${urlId}/target-audience/`,
      }),
    }),
    createURLSummary: build.mutation<LCRSummary, string>({
      query: (urlId) => ({
        method: 'POST',
        url: `urls/${urlId}/summary/`,
      }),
      invalidatesTags: () => ['getURLSummary'],
    }),
    getUnreadURLs: build.query<GetURLListResponse<LCRURL>, void>({
      query: () => ({
        method: 'GET',
        url: 'urls/',
        params: {
          unread: true,
          status: 'completed',
        },
      }),
      providesTags: () => ['unreadURLs'],
    }),
    markURLAsRead: build.mutation<LCRURL, string>({
      query: (urlId) => ({
        method: 'PUT',
        url: `urls/${urlId}/mark-as-read/`,
        body: {},
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const readedURL = await queryFulfilled;
          const queryArgs = getPOCQueryArgs(getState, 'getUnreadURLs');

          if (queryArgs) {
            dispatch(
              apiPOC.util.updateQueryData('getUnreadURLs', undefined, (draft) => {
                const readedURLIndex = draft.results.findIndex((url) => url.id === readedURL.data.id);
                if (readedURLIndex !== -1) {
                  draft.results.splice(readedURLIndex, 1);
                  draft.count = draft.results.length;
                }
                return draft;
              }),
            );
          }
        } catch {
          //
        }
      },
    }),
    getURLSummary: build.query<LCRSummary, string>({
      query: (urlId) => ({
        method: 'GET',
        url: `urls/${urlId}/summary/`,
      }),
      providesTags: () => ['getURLSummary'],
    }),
    retryURLSummary: build.mutation<LCRSummary, number>({
      query: (summaryId) => ({
        method: 'POST',
        url: `summaries/${summaryId}/retry/`,
      }),
      invalidatesTags: () => ['getURLSummary'],
    }),
    addTargetAudiences: build.mutation<LCRURL, AddTargetAudienceFormValues>({
      query: ({ urlIdOrHash, target_audiences }) => ({
        method: 'POST',
        url: `urls/${urlIdOrHash}/target-audience/`,
        body: {
          target_audiences,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const url = await queryFulfilled;
          const queryArgs = getPOCQueryArgs(getState, 'getURLDetail');

          if (queryArgs && queryArgs.originalArgs) {
            dispatch(
              apiPOC.util.updateQueryData('getURLDetail', queryArgs.originalArgs as { urlIdOrHash: string; include?: Array<URLExtraFields> }, (draft) => {
                return { ...draft, ...url.data };
              }),
            );
          }
        } catch {
          //
        }
      },
    }),
    exportTargetAudiences: build.mutation<void, ExportTargetAudienceFormValues>({
      query: ({ urlIdOrHash, target_audiences, url }) => ({
        method: 'POST',
        url: `urls/${urlIdOrHash}/target-audience/export/`,
        body: {
          target_audiences,
        },
        responseHandler: async (response) => {
          if (response.ok) {
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = `linkin-context-report-${url}.docx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            return { data: {} };
          }
          return response.json();
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

const {
  useGetURLTargetAudienceQuery,
  useLazyGetURLTargetAudienceQuery,
  useGetURLDetailQuery,
  useLazyGetURLDetailQuery,
  useCreateURLSummaryMutation,
  useGetUnreadURLsQuery,
  useLazyGetUnreadURLsQuery,
  useMarkURLAsReadMutation,
  useGetURLSummaryQuery,
  useLazyGetURLSummaryQuery,
  useRetryURLSummaryMutation,
  useAddTargetAudiencesMutation,
  useExportTargetAudiencesMutation,
} = apiPOC;

const { useGetURLListQuery, useLazyGetURLListQuery, useCreateURLAudienceMutation, useRetryURLAudienceMutation } = lcrAPI;

export {
  useGetURLListQuery,
  useLazyGetURLListQuery,
  useCreateURLAudienceMutation,
  useGetURLTargetAudienceQuery,
  useLazyGetURLTargetAudienceQuery,
  useGetURLDetailQuery,
  useLazyGetURLDetailQuery,
  useCreateURLSummaryMutation,
  useRetryURLAudienceMutation,
  useGetUnreadURLsQuery,
  useLazyGetUnreadURLsQuery,
  useMarkURLAsReadMutation,
  useGetURLSummaryQuery,
  useLazyGetURLSummaryQuery,
  useRetryURLSummaryMutation,
  useAddTargetAudiencesMutation,
  useExportTargetAudiencesMutation,
};

export default apiPOC;
