import { Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';
import SeparatorLine from '../SeparatorLine';
import cn from '@/utils/style';
import Checkbox from '../Checkbox';

interface CollapsableProps extends PropsWithChildren {
  isOpen: boolean;
  onToggle: () => void;
  content?: JSX.Element;
  disabled?: boolean;
  onCheck?: () => void;
  checked?: boolean;
  extraButtons?: Array<ExtraButton>;
}

interface ExtraButton {
  icon: ReactNode;
  onClick: () => void;
}

const Collapsable: FC<CollapsableProps> = ({ isOpen, onToggle, content, children, disabled, onCheck, checked, extraButtons }) => {
  return (
    <Fragment>
      <div className={cn('flex flex-row flex-nowrap gap-4 pr-4', content ? 'items-start' : 'items-center')}>
        <button onClick={onToggle} disabled={disabled}>
          <ChevronUpIcon className={cn('w-7 text-slate-400', !isOpen && 'rotate-180')} />
        </button>
        {onCheck && <Checkbox onChange={onCheck} className='mt-1' checked={checked} />}
        <div className='w-full'>
          {content ? (
            <div
              role='button'
              onClick={(event) => {
                event.stopPropagation();
                onToggle();
              }}
            >
              {content}
            </div>
          ) : (
            <SeparatorLine />
          )}
          <Transition
            as={Fragment}
            enter='transform transition duration-200'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transform duration-200 transition ease-in-out'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            show={isOpen}
          >
            {children}
          </Transition>
        </div>
        {extraButtons && (
          <div>
            {extraButtons.map(({ icon, onClick }, index) => (
              <button key={`extra-button-${index}`} onClick={onClick} type='button'>
                {icon}
              </button>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Collapsable;
