import { Button, Modal } from '@/components';

import { KEYWORDS_COUNT, URL_SELECTION_OPTIONS, URLS_COUNT } from '@/constants/defaultValues';
import { CombinationSchema } from '@/utils/combinations';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { FormikProvider, useFormik } from 'formik';
import { Fragment, useMemo } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const AddUrlModalSchema: yup.SchemaOf<AddUrlFormValues> = yup.object({
  competitors_keywords: yup.number().required('Competitors / Keywords is required'),
  keywords: yup.number().required('Keywords is required'),
  url_selection: yup.mixed<UrlSelection>().required('URL Selection is required'),
  urls_list: yup.string(),
  combinations: yup.array().of(CombinationSchema),
  domain: yup.string().when('url_selection', {
    is: 'from_domain',
    then: yup.string().required('A domain or URL is required'),
  }),
});

const AddUrlModal = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const isRootRoute = useMemo(() => !location.pathname.includes('manual') && !location.pathname.includes('organic-research'), [location.pathname]);

  const handleSubmit = (values: AddUrlFormValues) => {
    const { url_selection } = values;

    if (url_selection === 'from_domain') {
      return navigate('organic-research');
    }

    if (url_selection === 'urls_kws_manually') {
      return navigate('manual');
    }
  };

  const formik = useFormik<AddUrlFormValues>({
    onSubmit: handleSubmit,
    initialValues: {
      competitors_keywords: URLS_COUNT[0].value,
      keywords: KEYWORDS_COUNT[0].value,
      url_selection: URL_SELECTION_OPTIONS[0].value,
      urls_list: '',
      combinations: [{ url: '', keywords: [], location_code: null }],
      domain: '',
    },
    validationSchema: AddUrlModalSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  return (
    <Fragment>
      <Modal
        isOpen
        title={
          <div className='flex items-center gap-2'>
            <button onClick={() => navigate(-1)}>
              <ArrowLeftIcon className='w-6 text-slate-800' />
            </button>
            <span>Add Combination</span>
          </div>
        }
        onClose={() => navigate('../')}
      >
        <FormikProvider value={formik}>
          <Outlet context={formik} />
          {isRootRoute && (
            <div className='mt-4 flex w-full items-center gap-2 pt-4'>
              <Button className='w-full' variant='outline-light' onClick={() => navigate('../')}>
                Cancel
              </Button>
              <Button className='w-full' onClick={formik.submitForm}>
                Confirm
              </Button>
            </div>
          )}
        </FormikProvider>
      </Modal>
    </Fragment>
  );
};

export default AddUrlModal;
