import { FC, PropsWithChildren, ReactNode } from 'react';
import { Collapsable } from '@/components';
import cn from '@/utils/style';

interface CollapsableCardProps extends PropsWithChildren {
  content?: JSX.Element;
  containerClass?: string;
  isOpen: boolean;
  onToggle: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  onCheck?: () => void;
  checked?: boolean;
  extraButtons?: Array<ExtraButton>;
}

interface ExtraButton {
  icon: ReactNode;
  onClick: () => void;
}

const CollapsableCard: FC<CollapsableCardProps> = ({ children, content, containerClass, isOpen, onToggle, disabled, isLoading, onCheck, checked, extraButtons }) => (
  <div className={cn('relative mt-6 rounded-leap p-4 shadow-sm', containerClass)}>
    <Collapsable isOpen={isOpen} onToggle={onToggle} content={content} disabled={disabled} onCheck={onCheck} checked={checked} extraButtons={extraButtons}>
      <div>{children}</div>
    </Collapsable>
    {isLoading && <div className='absolute bottom-0 left-0 right-0 top-0 animate-pulse rounded-leap bg-gray-400 bg-opacity-30'></div>}
  </div>
);

export default CollapsableCard;
