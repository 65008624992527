import { Page } from '@/layouts';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import NewCampaignForm from '../NewCampaignForm';
import { FormikProvider, useFormik } from 'formik';
import { KEYWORDS_COUNT, URLS_COUNT, URL_SELECTION_OPTIONS } from '@/constants/defaultValues';
import * as yup from 'yup';
import { parseCombinations, parseUrls } from '@/utils/urls';
import { getUserHasCredits } from '@/utils/users';
import { getCountryOptions } from '@/utils/countries';
import { useLazyGetCurrentUserQuery } from '@/store/users/users.api';
import { useCreateCampaignMutation } from '@/store/campaign/campaign.api';
import { getBudgetValue } from '@/utils/campaigns';
import { Button, ErrorMessage, Modal, SuccessModal } from '@/components';
import { unstable_useBlocker as useBlocker, useNavigate } from 'react-router-dom';
import ConfirmCampaignModal from '../ConfirmCampaignModal';
import { useToggle } from '@/hooks/toggle';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { selectCountryList } from '@/store/campaign/campaign.selector';
import { CombinationSchema } from '@/utils/combinations';

const EMPTY_COMBINATION = { url: '', keywords: [], location_code: null };

const CampaignValidationSchema: yup.SchemaOf<NewCampaignFormValues> = yup.object({
  name: yup.string().required('name is required'),
  client_domain: yup.string().required('client domain is required'),

  urls_list: yup.string().when(['url_selection'], {
    is: (url_selection: UrlSelection) => url_selection !== 'urls_kws_manually',
    then: yup.string().required('URLs are needed in order to create this Campaign'),
  }),

  keyword_count: yup.number().required(),
  url_count: yup.number().required(),
  country: yup.string().required(),

  combinations: yup
    .array()
    .of(CombinationSchema)
    .when(['url_selection'], {
      is: (url_selection: UrlSelection) => url_selection === 'urls_kws_manually',
      then: yup.array().of(CombinationSchema).min(1),
    }),

  backlinks_profile: yup.boolean().required().default(false),

  budget: yup.string(),
  url_selection: yup.mixed<UrlSelection>().required(),
});

const NewCampaignContainer: FC = () => {
  const navigate = useNavigate();
  const user = useSelector(selectAuthUser);
  const countryList = useSelector(selectCountryList);

  const [fetchUser] = useLazyGetCurrentUserQuery();
  const [createCampaign, { isLoading, error: createError, isSuccess }] = useCreateCampaignMutation();

  const { value: isConfirmCampaignModalOpen, toggle: toggleConfirmCampaignModal } = useToggle();
  const [hasCredits, setHasCredits] = useState(true);

  const [areCombinationsValid, setAreCombinationsValid] = useState(true);

  const countriesOptions = useMemo(() => getCountryOptions(countryList), [countryList]);
  const error = useMemo(() => createError as APIError, [createError]);

  const navigateToHome = () => navigate('/');

  const handleSubmit = useCallback(
    async (values: NewCampaignFormValues) => {
      const user = await fetchUser().unwrap();
      if (!getUserHasCredits(user)) {
        setHasCredits(false);
        return;
      }
      const isCombination = values.combinations && values.combinations.length > 0;
      const combinations = isCombination ? parseCombinations(values.combinations) : parseUrls(values.urls_list || '').map((url) => ({ keywords: [], url, location_code: null }));
      await createCampaign({
        name: values.name,
        client_domain: values.client_domain,
        country: values.country,
        keyword_count: values.keyword_count,
        url_count: values.url_count,
        budget: getBudgetValue(values.budget),
        combinations,
        backlinks_profile: values.backlinks_profile,
      });
    },
    [createCampaign, fetchUser],
  );

  const formik = useFormik<NewCampaignFormValues>({
    onSubmit: (values) => handleSubmit(values),
    initialValues: {
      name: '',
      client_domain: '',
      urls_list: '',
      budget: '',
      country: countriesOptions[0].value,
      keyword_count: KEYWORDS_COUNT[0].value,
      url_count: URLS_COUNT[0].value,
      url_selection: URL_SELECTION_OPTIONS[0].value,
      combinations: [],
      backlinks_profile: true,
    },
    validationSchema: CampaignValidationSchema,
  });

  // reset list every time you change URL selection
  useEffect(() => {
    formik.setFieldValue('urls_list', '');
    formik.setFieldValue('combinations', [EMPTY_COMBINATION]);
  }, [formik.values.url_selection]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = useCallback(() => {
    toggleConfirmCampaignModal(false);
    formik.handleSubmit();
    formik.setStatus({ isSubmitted: true });
  }, [formik, toggleConfirmCampaignModal]);

  const blocker = useBlocker(() => formik.dirty && !(formik.status as { isSubmitted?: boolean })?.isSubmitted);

  const proceed = useCallback(() => blocker.proceed && blocker.proceed(), [blocker]);

  const reset = useCallback(() => blocker.reset && blocker.reset(), [blocker]);

  const shouldWarningModalOpen = useMemo(() => blocker.state === 'blocked', [blocker]);

  return (
    <Fragment>
      <Modal isOpen={shouldWarningModalOpen} className='w-modal' title='Warning!' onConfirm={proceed} onClose={reset}>
        <p>You have unsaved changes in your campaign creation. Are you sure you want to leave this page? All progress will be lost.</p>
      </Modal>
      <Page.Body>
        <FormikProvider value={formik}>
          <NewCampaignForm values={formik.values} onValidateCombinations={setAreCombinationsValid} />
        </FormikProvider>

        {isConfirmCampaignModalOpen && <ConfirmCampaignModal isOpen campaign={formik.values} onConfirm={handleFormSubmit} onClose={() => toggleConfirmCampaignModal(false)} />}

        {!hasCredits && <ErrorMessage className='mt-2 justify-end'>You have no credits left. Please contact your account manager to purchase more credits.</ErrorMessage>}

        {error?.data.non_field_errors && <ErrorMessage className='mt-2'>{error.data.non_field_errors.join('. ')}</ErrorMessage>}
      </Page.Body>
      <Page.Footer>
        <div className='flex w-full items-center justify-end gap-4 py-2'>
          <div className='mr-auto'>Available Credits: {user?.company?.credits?.dataforseo || '-'}</div>
          <Button variant='outline-light' onClick={navigateToHome} isLoading={isLoading}>
            Cancel
          </Button>
          <Button onClick={() => toggleConfirmCampaignModal(true)} isLoading={isLoading} disabled={!areCombinationsValid}>
            Create Campaign
          </Button>
        </div>
      </Page.Footer>
      {isSuccess && <SuccessModal isOpen={isSuccess} message='Your campaign was successfully created' onConfirm={() => navigateToHome()} />}
    </Fragment>
  );
};

export default NewCampaignContainer;
