import Modal from '../Modal';
import { useToggle } from '@/hooks/toggle';
import { SideMenuItem } from '../SideBar/components';
import { DataPartnersIcon } from '../Icons';
import { useSelector } from 'react-redux';
import { selectSideBarConfig } from '@/store/app/app.selector';
import DATA_PARTNERS from '@/constants/dataPartners';
import cn from '@/utils/style';

type Partner = typeof DATA_PARTNERS[number];

const DataPartnersModal = () => {
  const { isOpen: isSidebarOpen } = useSelector(selectSideBarConfig);
  const { value: isOpen, toggle } = useToggle();
  const renderPartner = ({ metrics, logo, name }: Partner) => {
    return (
      <li key={name} className='flex flex-col items-center gap-2 rounded-leap bg-slate-100 px-4 pb-8 pt-4'>
        <img src={logo} className={cn(name === 'Semrush' ? 'h-[60px] w-[240px]' : 'scale-50')} />
        <div>{metrics.join(', ')}</div>
      </li>
    );
  };
  return (
    <>
      <SideMenuItem onClick={() => toggle(true)} isSidebarOpen={isSidebarOpen} title='Data Partners' url='/data-partners' Icon={() => <DataPartnersIcon />} />
      <Modal
        isOpen={isOpen}
        title={
          <div className='font-normal '>
            <h1 className='text-xl'>About Our Data Partners</h1>
            <p className='text-base'>Link Launch uses data collected from the following partners:</p>
          </div>
        }
        className='w-[40rem]'
        onClose={() => toggle(false)}
      >
        <div>
          <ul className='space-y-2 px-2'>{DATA_PARTNERS.map(renderPartner)}</ul>
        </div>
      </Modal>
    </>
  );
};

export default DataPartnersModal;
