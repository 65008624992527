import { SERPFeaturesMap } from '@/utils/serpFeatures';
import Tooltip from '../Tooltip';
import { Row } from '@tanstack/react-table';
import { ArticleIcon } from '@/components/Icons';
import { formatSerp } from '@/utils/text';

interface SERPFeaturesCellProps<T> {
  row: Row<T>;
}

const SERPFeaturesCell = <T extends { serpFeaturesByKeyword: string[] | null }>({ row }: SERPFeaturesCellProps<T>) => {
  const { serpFeaturesByKeyword } = row.original;

  const slicedSerpFeaturesByKeyword = serpFeaturesByKeyword?.slice(0, 2);
  const moreSerpFeatures = serpFeaturesByKeyword?.slice(2);

  const renderMoreSerpFeatures = (serpFeature: string) => {
    const { icon: Icon, text } = SERPFeaturesMap[serpFeature] || { text: formatSerp(serpFeature), icon: ArticleIcon };
    return (
      <div key={serpFeature} className='my-2 flex gap-2'>
        <Icon className='size-6 text-slate-400' />
        <span className='whitespace-nowrap first-letter:capitalize'>{formatSerp(text)}</span>
      </div>
    );
  };

  return (
    <div>
      <div className='flex gap-2'>
        {slicedSerpFeaturesByKeyword?.map((serpFeature) => {
          const { icon: Icon, text } = SERPFeaturesMap[serpFeature] || { text: formatSerp(serpFeature), icon: ArticleIcon };

          return (
            <Tooltip
              className='border border-slate-300'
              content={<span className='whitespace-nowrap first-letter:capitalize'>{formatSerp(text)}</span>}
              opacity={1}
              key={`${serpFeature}-${text}`}
              id={`${serpFeature}-${text}`}
              place='top'
              variant='light'
            >
              <Icon className='size-6 text-slate-400' />
            </Tooltip>
          );
        })}
        {moreSerpFeatures && moreSerpFeatures.length > 0 && (
          <Tooltip
            variant='light'
            place='bottom'
            opacity={1}
            className='max-h-32 overflow-y-auto border border-slate-300'
            id={moreSerpFeatures.join(',')}
            content={<div>{moreSerpFeatures.map(renderMoreSerpFeatures)}</div>}
          >
            <span className='block pt-0.5'>+{moreSerpFeatures?.length}</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
export default SERPFeaturesCell;
